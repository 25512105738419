<template>
  <el-dialog :title="'数据质量标准'" :close-on-click-modal="false" :visible.sync="visible">
    <div style="height:auto">
        <iframe ref="iframe" id="iframe" scrolling="no" width="100%" height="100px" style="border: 0"  v-bind:src="dqHtmlUrl">

        </iframe>
    </div>
    
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dqHtmlUrl:"",
      measurePointId:0,
      iframeUrl:this.$store.state.dq_iframe,
    };
  },
  mounted(){

  },
  methods: {
    /**
     * 初始化信息
     */
    init(id) {
      this.visible = true;
      //加时间戳是为了防止浏览器读取缓存，获取不到最新页面信息 
      this.dqHtmlUrl=this.iframeUrl+"dataGovern/dataQualityIframe"+id+".html?t="+new Date().toLocaleString();
      this.measurePointId=measurePointId;
    }, 
    
  },
};
</script>
<style scoped>

</style>