<template>
  <el-dialog :title=" '上传数据标准信息页面'" :close-on-click-modal="false" :visible.sync="visible">

    <div class="">
        <el-form label-width="150px">
            <el-form-item label="名称">
                <el-input v-model="dataQualityName" type="text" placeholder="请输入数据标准名称" size="medium"></el-input>
            </el-form-item>
            <el-form-item label="数据质量类别">
                <el-select v-model="dataQualityType" placeholder="请选择">
                    <el-option
                    v-for="item in dataQualityTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="页面上传">
              <el-upload class="upload-demo" action="#"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-change="fileChange"
                multiple :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList">
                <el-button size="samll" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">请上传html页面</div>
              </el-upload>
            </el-form-item>

            <el-form-item label="解析页面信息jar包">
                <el-upload class="upload-demo" action="#"
                    :on-preview="handlePreviewJar"
                    :on-remove="handleRemoveJar"
                    :before-remove="beforeRemoveJar"
                    :on-change="fileChangeJar"
                    multiple :limit="1"
                    :on-exceed="handleExceedJar"
                    :file-list="jarFileList">
                    <el-button size="samll" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">请上传jar包</div>
                </el-upload>
            </el-form-item>

            <el-form-item label="判断函数jar包">
                <el-upload class="upload-demo" action="#"
                    :on-preview="handlePreviewJudge"
                    :on-remove="handleRemoveJudge"
                    :before-remove="beforeRemoveJudge"
                    :on-change="fileChangeJudge"
                    multiple :limit="1"
                    :on-exceed="handleExceedJudge"
                    :file-list="judgeFileList">
                    <el-button size="samll" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">请上传jar包</div>
                </el-upload>
            </el-form-item>

            <el-form-item>
                <el-button @click="closeDialog()" size="medium">取消</el-button>
                <el-button @click="uploadHtml()" size="medium">确定</el-button>
            </el-form-item>
            
        </el-form>
        
  </div>
  </el-dialog>
</template>

<script>


export default {
  name:'dataQualityIframeUpload',
  components:{

  },
  data() {
    return {
      dataQualityName:"",
      dataQualityType:"",
      visible: false,
      fileList: [],
      jarFileList:[],
      judgeFileList:[],
      dataQualityTypes: [{
          value: '完整性',
          label: '完整性'
        }, {
          value: '合理性',
          label: '合理性'
        }, {
          value: '准确性',
          label: '准确性'
        }, {
          value: '时效性',
          label: '时效性'
        }],
    };
  },
  created(){
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      this.dataQualityName="";
      this.dataQualityType="";
      this.fileList=[];
      this.jarFileList=[];
      this.judgeFileList=[];
      this.visible=true;
    },

    /**
     * 点击取消按钮
     */
    closeDialog(){
        this.fileList=[];
        this.jarFileList=[];
        this.judgeFileList=[];
        this.visible=false;
    },

    /**
     * 点击确定按钮，上传页面
     */
    uploadHtml(){
        let formData=new FormData();
        /* this.fileList.forEach(file=>{
            formData.append('file',file.raw);
        }); */
        formData.append('file',this.fileList[0].raw);//html文件
        formData.append('jarFile',this.jarFileList[0].raw);//信息解析jar包
        formData.append('judgeFile',this.judgeFileList[0].raw);//判断函数jar包
        formData.append('dataQualityName',this.dataQualityName);
        formData.append('dataQualityType',this.dataQualityType);
        this.postRequest("/dataQualityIframe/dataQualityIframeUpload",formData).then(response=>{
            if(response&&response.status===200){
                this.$parent.initData();
                this.visible=false;
            }
        }).then(error=>{
            
        })
    },


    handleRemove(file, fileList) {
        console.log(file, fileList);
    },
    handleRemoveJar(jarFile, jarFileList) {
        console.log(jarFile, jarFileList);
    },
    handleRemoveJudge(judgeFile, judgeFileList) {
        console.log(judgeFile, judgeFileList);
    },

    handlePreview(file) {
        console.log(file);
    },
    handlePreviewJar(jarFile) {
        console.log(jarFile);
    },
    handlePreviewJudge(judgeFile) {
        console.log(judgeFile);
    },

    handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleExceedJar(jarFiles, jarFileList) {
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${jarFiles.length} 个文件，共选择了 ${jarFiles.length + jarFileList.length} 个文件`);
    },
    handleExceedJudge(judgeFiles, judgeFileList) {
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${jarFiles.length} 个文件，共选择了 ${jarFiles.length + jarFileList.length} 个文件`);
    },

    beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
    },
    beforeRemoveJar(jarFile, jarFileList) {
        return this.$confirm(`确定移除 ${ jarFile.name }？`);
    },
    beforeRemoveJudge(judgeFile, judgeFileList) {
        return this.$confirm(`确定移除 ${ judgeFile.name }？`);
    },

      // 文件改变时
    fileChange(file, fileList) {
        this.fileList=fileList;
        this.fileList.append(file);
    },
    fileChangeJar(jarFile, jarFileList) {
        this.jarFileList=jarFileList;
        this.jarFileList.append(jarFile);
    },
    fileChangeJudge(judgeFile, judgeFileList) {
        this.judgeFileList=judgeFileList;
        this.judgeFileList.append(judgeFile);
    },

  },
};
</script>
<style scoped>
    .el-input{
        width: 260px;
    }
</style>
